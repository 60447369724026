
import './AppNavigation.css';
import HeaderNavigation from './Header/HeaderNavigation.js';
import TableNavigation from './TableNavigation/TableNavigation';

function AppNavigation(props) {
  return (
    <div id='AppNavigationContainer'>
      <HeaderNavigation />
      <TableNavigation selectedOption={props.selectedOption} selectOption={props.selectOption} />
    </div>
  );
}

export default AppNavigation;
