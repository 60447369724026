import { BlueButton } from "../../../../../Classes/BlueButton/BlueButton";
import OverviewHeader from "../../Header/OverviewHeader";
import styles from "./OverviewContaineSelectOptions.module.css";
import uploadFileExcel from "../../../../../images/UploadFileExcel.png";
import getStartedFile from "../../../../../images/GetStartedFile.png";

export function OverviewContaineSelectOptions(props) {
  function downloadExcel() {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/csv/model.csv`; // Adjust the path if necessary
    console.log(link.href);
    link.download = "model.csv"; // The name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={styles.rootContainer}>
      <div className={styles.backgroundColorWithPadding}>
        <div className={styles.header}>Cargo Info</div>
        <div className={styles.options}>
          <div className={styles.uploadFile}>
            <img src={uploadFileExcel} />
            <div className={styles.uploadFileText}>
              Effortlessly Upload Your Transport
              <br /> Information from an existing Excel File.
            </div>
            <BlueButton
              title="Download excel model"
              onClick={() => downloadExcel()}
            />
            <BlueButton
              title="Upload excel file"
              onClick={() => alert("Stay tuned!")}
            />
          </div>
          <div className={styles.getStarted}>
            <img src={getStartedFile} />
            <div className={styles.getStartedText}>
              Enter Transport Details Manually for
              <br /> Ultimate Control and Flexibility.
            </div>
            <BlueButton title="Get started" onClick={props.nextStep} />
          </div>
        </div>
      </div>
    </div>
  );
}
