import './HeaderNavigation.css';
import headerImage from '../../../images/headerImage.png';

function HeaderNavigation() {
    return (
      <div className="headerContainer">
        <div className="imageContainer">
            <img src={headerImage} />
        </div>
        <div className='textContainer'>
            <div className="greetingsContainer">
                Good morning
            </div>
            <div className="usernameContainer">
                Pathium Team
            </div>
        </div>
      </div>
    );
  }
  
  export default HeaderNavigation;
  