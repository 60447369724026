import { useState } from 'react';
import OverviewContentLatestTranspots from '../Overview/Content/OverviewContentLatestTranspots/OverviewContentLatestTranspots';
import OverviewFooter from '../Overview/Footer/OverviewFooter';
import OverviewHeader from '../Overview/Header/OverviewHeader';
import './HistoryContainer.css';

function HistoryContainer({ title, transports }) {

  return (
    <div id="HistoryContainer">
      <div id="HistoryHeaderContainer">
        <OverviewHeader title={title} />
      </div>
      <div id="HistoryContentContainer">
        <OverviewContentLatestTranspots transports={transports} />
      </div>

      <OverviewFooter />
    </div>
  );

}
  
export default HistoryContainer;
  