import './TableCell.css';

function TableCell(props) {
    return(
        <div className='TableCell' onClick={() => {props.selectOption(props.name)}}>
            <div className='tableCellImageContainer'>
                <img src={props.image} />
            </div>
            <div className='tableCellTextContainer'>
                {props.name}
            </div>
        </div>
    );
}

export default TableCell;