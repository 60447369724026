import { CSVLink } from "react-csv";
import "./OverviewContentLatestDataHeader.css";
import StaticFunctions from "../../../../../Managers/StaticFunctions";

function OverviewContentLatestDataHeader(props) {
  function composeExportHeaders() {
    if (
      props.exportData === undefined ||
      props.exportData.headers === undefined
    ) {
      return [];
    }

    return props.exportData.headers;
  }

  function composeExportData() {
    if (props.exportData === undefined || props.exportData.data === undefined) {
      return [];
    }

    const data = props.exportData.data.map((transport) => {
      return {
        "Client name": transport.clientName,
        "Transport company": transport.companyName,
        "Driver name": transport.driverName,
        Distance: transport.totalDistance,
        "Transport name": transport.name,
        Departure:
          transport.routes == null ? null : transport.routes[0].locationName,
        Arrival:
          transport.routes == null
            ? null
            : transport.routes[transport.routes.length - 1].locationName,
        "Services Offered": StaticFunctions.getAllServiesFromTags(transport),
        "Tag status": StaticFunctions.getNumberOfAlerts(transport),
      };
    });

    return data;
  }

  return (
    <div id="OverviewContentLatestDataCargoInDangerHeader">
      <div id="OverviewContentLatestDataCargoInDangerTitle">{props.title}</div>
      {props.hideDownload ? null : (
        <CSVLink data={composeExportData()} headers={composeExportHeaders()}>
          <div id="OverviewContentLatestDataCargoInDangerDownload"></div>
        </CSVLink>
      )}
      <div
        onClick={(e) => {}}
        id="OverviewContentLatestDataCargoInDangerMore"
      ></div>
    </div>
  );
}

export default OverviewContentLatestDataHeader;
