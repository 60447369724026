import { useEffect, useState } from 'react';
import './ListWithCitiesTransportContainer.css';
import AlertTransportCell from './AlertTransportCell';

function ListWithCitiesTransportContainer({ route }) {

    const [expanded, setExpanded] = useState(false);

    const expandOrCollapse = () => {
        setExpanded(!expanded)
    }
    
    useEffect(() => {
        // setExpanded(false)
    }, [route])

  return (
    <div className='listWithCitiesTransportCell' onClick={expandOrCollapse}>
        <div id="listWithCitiesTransportCellHeader">
            <div id="listWithCitiesTransportCellDate">{route.date}</div>
            
            <div id="listWithCitiesTransportCellFromPlace">{route.fromPlace}</div>
            
            <div id="listWithCitiesTransportCellNumberOfAlerts">{route.numberOfAlerts} alerts</div>
            
            <div id="listWithCitiesTransportCellStatusMetaTags">{route.statusMetaTags}</div>
            
            <div id="listWithCitiesTransportCellToPlace">{route.toPlace}</div>

            { expanded ? <div id="listWithCitiesTransportCellArrowUp"></div> : <div id="listWithCitiesTransportCellArrowDown"></div> }
        </div>
        
        { 
        (expanded) ?
        <div id="listWithCitiesTransportCellDetails">
            {
                route.alertList.map((alert, index) => {
                    return <AlertTransportCell key={`listWithCitiesTransportCellDetails_${index}`} alert={alert} />
                })
            }
        </div> : null
        }
        
    </div>
  );

}
  
export default ListWithCitiesTransportContainer;
  