import './OverviewFooter.css';
import facebook from '../../../../images/socialFacebook.png'
import linkedn from '../../../../images/socialLinkedin.png'
import twitter from '../../../../images/socialTwitter.png'
import youtube from '../../../../images/socialYoutube.png'

function OverviewFooter() {

    const redirectTo = (url) => {
        window.open(url, '_blank')
    }

    return (
        <div id="overviewFooterContainer">
            <div id='overviewFooterName'>
                <div id='overviewFooterNameVersion'>MetaVision 1.0.0</div>
                <div id='overviewFooterNameCopyright'>© Pathium, 2023 All Rights Reserved</div>
            </div>
            <div id='overviewFooterLinks'>
                <div className='overviewFooterLink'>
                    <div>Analytics</div>
                    <div>Activity</div>
                    <div>Publishing</div>
                </div>
                <div className='overviewFooterLink'>
                    <div>Influencers</div>
                    <div>Content</div>
                    <div>Resources</div>
                </div>
                <div className='overviewFooterLink'>
                    <div>Product changes</div>
                    <div>Company</div>
                    <div>Careers</div>
                </div>
                <div className='overviewFooterLink'>
                    <div>Contacts</div>
                    <div>FAQ</div>
                    <div>Support</div>
                </div>
            </div>
            <div id='overviewFooterSocial'>
                <button onClick={e => redirectTo('https://www.instagram.com/pathium.neochain/')} className='overviewSocial'><img src={facebook} /></button>
                <button onClick={e => redirectTo('https://www.linkedin.com/company/pathium-neochain/')} className='overviewSocial'><img src={linkedn} /></button>
                <button onClick={e => redirectTo('https://www.twitter.com')} className='overviewSocial'><img src={twitter} /></button>
                <button onClick={e => redirectTo('https://www.youtube.com')} className='overviewSocial'><img src={youtube} /></button>
            </div>
        </div>
    );
}

export default OverviewFooter;