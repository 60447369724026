import { useEffect, useState } from "react";
import "./App.css";
import AppNavigation from "./Components/AppNavigation/AppNavigation.js";
import OverviewContainer from "./Components/AppContainer/Overview/OverviewContainer";
import HistoryContainer from "./Components/AppContainer/History/HistoryContainer";
import TrackingContainer from "./Components/AppContainer/Tracking/TrackingContainer";
import Cookies from "universal-cookie";
import LoginContainer from "./Components/AppContainer/Login/LoginContainer.js";
import { io } from "socket.io-client";
import { OverviewContainerAddMarkersFlow } from "./Components/AppContainer/Overview/Content/OverviewContent/OverviewContainerAddMarkersFlow.js";

function App() {
  const [option, setOption] = useState("Overview");
  const [showAll, setShowAll] = useState(false);
  const [transports, setTransports] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedTransport, setSelectedTransport] = useState(null);

  const u = [
    "ruben.bacanu",
    "cristian.vijaeac",
    "elvis.arteni",
    "cosmin.tomescu",
    "laura.baila",
  ];

  const handleOptionClick = (val) => {
    setOption(val);
    setSelectedTransport(null);
  };

  const cookies = new Cookies();

  const checkLogin = (pass) => {
    if (pass !== null && u.includes(pass.toLowerCase())) {
      cookies.set("user", pass.toLocaleLowerCase(), { path: "/" });
      window.location.reload();
    } else {
      alert("Invalid credentials!");
      setShowAll(false);
    }
  };

  const getAllTransportsLastLocations = (transports) => {
    var transportsLocations = [];
    if (transports != null) {
      transports.forEach((transport) => {
        transportsLocations.push({
          lat: transport.lastLocation.lat,
          lng: transport.lastLocation.long,
        });
      });
    }

    // mock location for wolves
    transportsLocations.push({
      lat: 48.206227,
      lng: 16.371718,
    });

    return transportsLocations;
  };

  const getSocketTransports = () => {
    const socket = io(
      "wss://pathium-metatags-1997cfc97c25.herokuapp.com/transports/subscribe"
    );
    socket.on("updateEvent", (value) => {
      console.log("from socket");
      console.log(value);
      setTransports(value);
      // setMarkers(getAllTransportsLastLocations(value));
      checkIfIsInTrackingAndSendUpdates(value);
    });
  };

  const getApiTransports = () => {
    fetch(
      "https://pathium-metatags-1997cfc97c25.herokuapp.com/transports/details"
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("from api");
        console.log(result);
        setTransports(result);
        // setMarkers(getAllTransportsLastLocations(result));
      });
  };

  const goToTracking = (transportId) => {
    transports.forEach((transport) => {
      if (transport.id == transportId) {
        setSelectedTransport(transport);
        setOption("Tracking");
      }
    });
  };

  const checkIfIsInTrackingAndSendUpdates = (value) => {
    if (option.toLowerCase() == "tracking" && selectedTransport != null) {
      value.forEach((transport) => {
        if (transport.id == selectedTransport.id) {
          setSelectedTransport(transport);
        }
      });
    }
  };

  useEffect(() => {
    if (u.includes(cookies.get("user"))) {
      setShowAll(true);
      getApiTransports();
      getSocketTransports();
    }
  }, []);

  return (
    <div>
      <div id="webView">
        {showAll ? (
          <div id="App">
            <div id="AppNavigation">
              <AppNavigation
                selectedOption={option}
                selectOption={handleOptionClick}
              />
            </div>
            <div id="AppContainer">
              {option.toLowerCase() === "overview" && markers.length != 0 ? (
                <OverviewContainer
                  markers={markers}
                  transports={transports}
                  title={option}
                  goToTracking={goToTracking}
                />
              ) : null}
              {option.toLowerCase() === "overview" && markers.length == 0 ? (
                <OverviewContainerAddMarkersFlow />
              ) : null}
              {option.toLowerCase() === "history" ? (
                <HistoryContainer title={option} transports={transports} />
              ) : null}
              {option.toLowerCase() === "tracking" ? (
                <TrackingContainer
                  transports={transports}
                  selectedTransport={
                    selectedTransport == null
                      ? transports[0]
                      : selectedTransport
                  }
                  title={option}
                  goToTracking={goToTracking}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <LoginContainer checkLogin={checkLogin} />
        )}
      </div>

      <div id="mobileView">
        <div id="messageForMobileLogo"></div>
        The dashboard only works on desktop!
        <br />
        Thank you!
      </div>
    </div>
  );
}

export default App;
