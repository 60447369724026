class AlertTransport {
    constructor(
            hour, 
            type, 
            value,
            notificationSend,
        ) {
            this.hour = hour
            this.type = type
            this.value = value
            this.notificationSend = notificationSend
        }
  }

  export default AlertTransport;