import './OverviewContentDivClientsTableCell.css';



function OverviewContentDivClientsTableCell(props) {

    const getStatusCircle = () => {
        return <div className={`OverviewContentDivClientsTableCellStatus_${props.transport.state}`}></div>
    }

    return (
    <div className="OverviewContentDivClientsTableCell" onClick={() => props.goToTracking(props.transport.id)} >
        <div className='OverviewContentDivClientsTableCellLogo'></div>
        <div className='OverviewContentDivClientsTableCellCompanyName'>{ props.transport.companyName }</div>
        <div className='OverviewContentDivClientsTableCellLocation'>{ (props.transport.routes == null) ? null : props.transport.routes[0].locationName }</div>
        <div className='OverviewContentDivClientsTableCellDistance'>{ props.transport.totalDistance }</div>
        <div className='OverviewContentDivClientsTableCellStatus'>{ getStatusCircle() }</div>
    </div>
    );
  }
  
  export default OverviewContentDivClientsTableCell;