import TableCell from "./TableCell/TableCell";
import './TableNavigation.css';
import overview from '../../../images/tableNavigationOverview.png';
import partners from '../../../images/tableNavigationPartners.png';
import tracking from '../../../images/tableNavigationTracking.png';
import analysis from '../../../images/tableNavigationAnalysis.png';
import history from '../../../images/tableNavigationHistory.png';

function TableNavigation(props) {
    return(
        <div className="TableNavigation">
            <TableCell selectedOption={props.selectedOption} image={overview} name="Overview" selectOption={props.selectOption} />
            {/* <TableCell selectedOption={props.selectedOption} image={partners} name="Partners" selectOption={props.selectOption} /> */}
            <TableCell selectedOption={props.selectedOption} image={tracking} name="Tracking" selectOption={props.selectOption} />
            {/* <TableCell selectedOption={props.selectedOption} image={analysis} name="Analysis" selectOption={props.selectOption} /> */}
            <TableCell selectedOption={props.selectedOption} image={history} name="History" selectOption={props.selectOption} />
        </div>
    );
}

export default TableNavigation;