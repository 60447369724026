class ListTransport {
    constructor(
            idFrom,
            idTo,
            date, 
            fromPlace, 
            toPlace, 
            numberOfAlerts, 
            statusMetaTags,
            alertList,
        ) {
            this.idFrom = idFrom
            this.idTo = idTo
            this.date = date
            this.fromPlace = fromPlace
            this.toPlace = toPlace
            this.numberOfAlerts = numberOfAlerts
            this.statusMetaTags = statusMetaTags
            this.alertList = alertList
        }
  }

  export default ListTransport;