import "./OverviewHeader.css";
import logoutButton from "../../../../images/logoutButton.png";
import Cookies from "universal-cookie";

function OverviewHeader(props) {
  const cookies = new Cookies();
  const logout = () => {
    cookies.remove("user", { path: "/" });
    window.location.reload();
  };

  return (
    <div id="overviewHeaderDiv">
      <div id="overviewTitle">{props.title}</div>
      {props.title.toLowerCase() == "tracking" ? null : (
        <div id="overviewSearch">
          <input placeholder="Search" />
        </div>
      )}
      {props.title.toLowerCase() == "tracking" ? null : (
        <div id="overviewCalendar">
          <button id="overviewCalendarButton">Calendar</button>
          <button id="overviewCalendarPrevious"></button>
          <button id="overviewDateButton">5 - 8 June</button>
          <button id="overviewCalendarNext"></button>
        </div>
      )}
      {props.title.toLowerCase() == "tracking" ? null : (
        <div id="overviewFilterCar">
          <select className="selectUnselected">
            <option value="car">Car</option>
            <option value="audi">Audi</option>
            <option value="bmw">BMW</option>
            <option value="mercedes">Mercedes</option>
          </select>
        </div>
      )}
      {props.title.toLowerCase() == "tracking" ? null : (
        <div id="overviewFilterLocation">
          <select className="selectUnselected">
            <option value="location">Location</option>
            <option value="bucharest">Bucharest</option>
            <option value="constanta">Constanta</option>
            <option value="brasov">Brasov</option>
            <option value="timisoara">Timisoara</option>
          </select>
        </div>
      )}
      {props.title.toLowerCase() == "tracking" ? null : (
        <div id="overviewFilterAlertType">
          <select className="selectUnselected">
            <option value="alertType">Alert type</option>
          </select>
        </div>
      )}
      <div id="overviewSettings" onClick={() => logout()}>
        <img src={logoutButton} />
      </div>
    </div>
  );
}

export default OverviewHeader;
