import { useState } from 'react';
import './LoginContainer.css';

function LoginContainer({ checkLogin }) {

    const [username, setUsername] = useState('');


    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            checkLogin(username)
        }
    }

    return (
        <div id="loginContainer">
            <div id="loginForm">
                <div id="loginFormSignIn">Sign in</div>
                <div id="loginFormWelcome">Welcome back</div>
                <div id="loginFormDescription">Sign in to continue to PATHIUM.</div>
                <input onKeyDown={handleKeyPress} id="loginFormInput" value={username} onChange={e => setUsername(e.target.value)} placeholder='Enter your email address' />
                <button  id="loginFormButton" onClick={() => checkLogin(username)}>Login</button>
            </div>
            <div id="loginImage"></div>
            <div id="loginContainerLogo"></div>
        </div>
    );
  }
  
  export default LoginContainer;