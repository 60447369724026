import OverviewContentLatestDataHeader from "../OverviewContentLatestDataHeader/OverviewContentLatestDataHeader";
import "./OverviewContentLatestTranspots.css";
import TransportsTableView from "./TransportsTableView";

function OverviewContentLatestTranspots(props) {
  const headerTableValues = [
    "Client name",
    "Transport company",
    "Driver name",
    "Distance",
    "Transport name",
    "Departure",
    "Arrival",
    "Services Offered",
    "Tag status",
  ];

  return (
    <div id="overviewContentLatestTranspots">
      <OverviewContentLatestDataHeader
        title="Latest Transports"
        exportData={{ headers: headerTableValues, data: props.transports }}
      />

      <div id="headerTable">
        {headerTableValues.map((title, index) => {
          return (
            <div key={`headerTable_${index}`} className="headerTableClass">
              {title.toUpperCase()}
            </div>
          );
        })}
      </div>

      <div id="tableView">
        {props.transports.map((value, index) => {
          return (
            <TransportsTableView
              key={`tableView_${index}`}
              theKey={`tableView_${index}`}
              transport={value}
            />
          );
        })}
      </div>
    </div>
  );
}

export default OverviewContentLatestTranspots;
