import OverviewContent from './Content/OverviewContent/OverviewContent';
import OverviewHeader from './Header/OverviewHeader';
import './OverviewContainer.css';
import OverviewFooter from './Footer/OverviewFooter';
import OverviewContentLatestDataCargoInDanger from './Content/OverviewContentLatestDataCargoInDanger/OverviewContentLatestDataCargoInDanger';
import OverviewContentLatestDataAlerts from './Content/OverviewContentLatestDataAlerts/OverviewContentLatestDataAlerts';
import OverviewContentLatestTranspots from './Content/OverviewContentLatestTranspots/OverviewContentLatestTranspots';

function OverviewContainer({ title, markers, transports, goToTracking }) {
  
  const calculateCenterOfTheMap = () => {
    return {
        lat: 48.205236,
        lng: 16.3693181,
      }
  }
  
  return (
    <div id="OverviewContainer">
      <div id="OverviewHeaderContainer">
        <OverviewHeader title={title} />
      </div>
      <div id="OverviewContentContainer">
        <OverviewContent center={calculateCenterOfTheMap()} markers={markers} zoomLevel={6} transports={transports} goToTracking={goToTracking} />

        <div id="overviewContentLatestDataDiv">
          <div id="overviewContentLatestHeader">
            <div>
              <h4 id="overviewContentDivTransit">Latest data</h4>
            </div>
          </div>
          
          <div id="overviewContentLatestData">
            <OverviewContentLatestDataAlerts transports={transports} />
            <OverviewContentLatestDataCargoInDanger transports={transports} goToTracking={goToTracking} />
          </div>
        </div>

        <OverviewContentLatestTranspots transports={transports} />
      </div>

      <OverviewFooter />
    </div>
  );

}
  
export default OverviewContainer;
  