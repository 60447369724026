import OverviewContentLatestDataHeader from "../OverviewContentLatestDataHeader/OverviewContentLatestDataHeader";
import "./OverviewContentLatestDataAlerts.css";
import { Bar } from "react-chartjs-2"; // https://react-chartjs-2.js.org/
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

function OverviewContentLatestDataAlerts(props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  const getAllEvents = (transports) => {
    var allEvents = [];
    transports.forEach((transport) => {
      transport.tags.forEach((tag) => {
        tag.events.forEach((event) => {
          allEvents.push(event);
        });
      });
    });

    return allEvents;
  };

  const getAllLabelsFrom = (events) => {
    var labels = [];
    events.forEach((event) => {
      const date = event.eventDate.substring(0, 10);
      if (!labels.includes(date)) {
        labels.push(date);
      }
    });

    return labels;
  };

  const getAllTypesFrom = (events) => {
    var labels = [];
    events.forEach((event) => {
      const date = event.eventType;
      if (!labels.includes(date)) {
        labels.push(date);
      }
    });
    return labels;
  };

  const getEventsValueFor = (events, type, labels) => {
    var values = [];

    labels.forEach((label) => {
      var sumOfEvents = 0;
      events.forEach((event) => {
        if (
          event.eventType == type &&
          label == event.eventDate.substring(0, 10)
        ) {
          sumOfEvents += 1;
        }
      });
      values.push(sumOfEvents);
    });

    return values;
  };

  const getColorFor = (type) => {
    var color = "#7967E8";

    if (type.toLowerCase() == "temperature") {
      color = "#FF0BAA";
    } else if (type.toLowerCase() == "uv") {
      color = "#FF2BAA";
    } else if (type.toLowerCase() == "light") {
      color = "#FF4BAA";
    } else if (type.toLowerCase() == "humidity") {
      color = "#FF6BAA";
    } else if (type.toLowerCase() == "gas") {
      color = "#FF8BAA";
    } else if (type.toLowerCase() == "motion") {
      color = "#48E1FF";
    } else if (type.toLowerCase() == "pressure") {
      color = "#48E2FF";
    } else if (type.toLowerCase() == "altitude") {
      color = "#48E3FF";
    } else if (type.toLowerCase() == "lost_signal") {
      color = "#8120FF";
    } else if (type.toLowerCase() == "proximity") {
      color = "#8122FF";
    }

    return color;
  };

  const getData = () => {
    var labels = [];
    var allEvents = [];
    var allTypes = [];

    if (props.transports != null) {
      allEvents = getAllEvents(props.transports);
      labels = getAllLabelsFrom(allEvents);
      allTypes = getAllTypesFrom(allEvents);
    }

    var dataSets = [];
    allTypes.forEach((type) => {
      const valuesForType = getEventsValueFor(allEvents, type, labels);

      const dataSet = {
        label: type,
        data: valuesForType,
        backgroundColor: getColorFor(type),
      };
      dataSets.push(dataSet);
    });

    const data = {
      labels,
      datasets: dataSets,
    };

    return data;
  };
  return (
    <div id="overviewContentLatestDataAlerts">
      <OverviewContentLatestDataHeader title="Alerts" hideDownload={true} />
      <div id="containerBar">
        <Bar id="barId" options={options} data={getData()} />
      </div>
    </div>
  );
}

export default OverviewContentLatestDataAlerts;
