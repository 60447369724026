import OverviewFooter from '../Overview/Footer/OverviewFooter';
import OverviewHeader from '../Overview/Header/OverviewHeader';
import './TrackingContainer.css';
import mapStyle from '../Overview/Content/OverviewContent/MapStyles';
import ListWithCitiesTransportContainer from './ListWithCitiesTransportContainer';
import ListTransport from '../../../Classes/ListTransport';
import AlertTransport from '../../../Classes/AlertTransport';
import { useEffect, useState } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

function TrackingContainer({ title, transports, selectedTransport, goToTracking }) {

  const [routes, setRoutes] = useState([])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({
    lat: 45.7202196,
    lng: 25.2059935,
  })

  const checkIfBackAndForwardButtonsAreShown = () => {
    const backButton = document.getElementById('informationAboutTransportBack')
    const forwardButton = document.getElementById('informationAboutTransportForward')

    if (selectedTransport.id == transports[0].id) {  
      backButton.style.opacity = 0.2
    } else {
      backButton.style.opacity = 1.0
    }

    if (selectedTransport.id == transports[transports.length - 1].id) {
      forwardButton.style.opacity = 0.2
    } else {
      forwardButton.style.opacity = 1.0
    }
  }

  const getCustomMarkerSymbol = () => ({
    path: window.google.maps.SymbolPath.CIRCLE, // Access SymbolPath through window.google
    scale: 5, // Adjust the size of the circle as needed
    strokeColor: '#246BFD', // Change the stroke color
    fillColor: '#246BFD', // Change the fill color
    fillOpacity: 1, // Adjust the opacity
  });

  const getMarkers = () => {
    var markersCreated = []
    selectedTransport.routes.forEach((route) => {
      markersCreated.push({
        lat: route.locationCoordinates.lat,
        lng: route.locationCoordinates.long
      })
    })

    return markersCreated
  }

  useEffect(() => {
    checkIfBackAndForwardButtonsAreShown()
    setRoutes(getRoutes())
    setMarkers(getMarkers())
  }, [selectedTransport])

  const getListWithMetatgsContainerLogo = (service, index) => {
    var classMetaTag = 'listWithMetatgsContainerLogoGPS'

    if (["MOTION", "ALTITUDE", "PRESSURE"].includes(service) ) {
      classMetaTag = 'listWithMetatgsContainerLogoBumps'
    } else if (["TEMPERATURE", "HUMIDITY", "LIGHT", "UV", "GAS"].includes(service)) { 
      classMetaTag = 'listWithMetatgsContainerLogoHeat'
    }

    return <div className={`listWithMetatgsContainerLogo ${classMetaTag}`} id={`listWithMetatgsContainer_${index}_logo`}></div>;
  }

  const checkAndMove = (move) => {
    var currentIndex = 0
    transports.forEach((transport, index) => {
      if (transport.id == selectedTransport.id) {
        currentIndex = index
      }
    })

    if (transports[currentIndex + move] != null) {
      goToTracking(transports[currentIndex + move].id)
    }
  }

  const getServices = () => {
    var services = []
    if (selectedTransport.tags != null) {
      selectedTransport.tags.forEach(tag => {
          tag.services.forEach((service) => {
              if (!services.includes(service)) {
                  services.push(service)
              }
          })
      });
    }
    return services
  }

  const getAllEventsFor = (routeId, addNullEvents) => {
    var events = []

    selectedTransport.tags.forEach((tag) => {
      tag.events.forEach((event) => {
        if(event.routeId == routeId) {
          events.push(event)
        } else if(event.routeId == null && addNullEvents) {
          events.push(event)
        }
      })
    })

    events.sort((a, b) => {
      return new Date(a.eventDate) - new Date(b.eventDate)
    })

    return events
  }

  const getAlertsFor = (routeId, addNullEvents) => {
    var alerts = [
      new AlertTransport(
        "06:00",
        "start",
        null,
        false
      )
    ]

    const events = getAllEventsFor(routeId, addNullEvents)
    events.forEach((event) => {
      alerts.push(new AlertTransport(
        event.eventDate.substring(11, 16),
        event.eventType,
        event.eventValue[0],
        false
      ))
    })

    return alerts
  }

  const getRoutes = () => {
    var routes = []
    if (selectedTransport.routes != null) {
      for(var i=0; i < selectedTransport.routes.length - 1; i++) {
        const currentRoute = selectedTransport.routes[i]
        const nextRoute = selectedTransport.routes[i + 1]

        const addNullEvents = ((i + 1) == selectedTransport.routes.length - 1)

        const listTransport = new ListTransport(
          currentRoute.id,
          nextRoute.id,
            currentRoute.dateOfArrival.substring(0, 10),
            currentRoute.locationName,
            nextRoute.locationName,
            getAllEventsFor(currentRoute.id, addNullEvents).length,
            "100% of metatags intact",
            getAlertsFor(currentRoute.id, addNullEvents)
        )
        routes.push(listTransport)
      }
    }

    return routes
  }

  const generatePlateNumber = () => {
    const digit1 = (selectedTransport.clientName.substring(0, 2)).charCodeAt(0) % 10
    const digit2 = (selectedTransport.clientName.substring(0, 2)).charCodeAt(1) % 10
    return `B ${digit1}${digit2} ${selectedTransport.clientName.substring(0, 3).toUpperCase()}`
  }

  const generatePhoneNumber = () => {
    var phoneNumber = "07"
    for(var i = selectedTransport.name.length - 1; i >= 0 && phoneNumber.length < 10; i--) {
      phoneNumber += selectedTransport.name[i].charCodeAt(0) % 10
    }

    return phoneNumber
  }

  return (
    <div id="TrackingContainer">
      <div id="TrackingHeaderContainer">
        <OverviewHeader title={title} />
      </div>
      <div id="TrackingContentContainer">
        <div id="DetailsContainer">
          <div id="DetailsAbouteTransport">

            <div id="informationAboutTransportWithBack">
              <div id="informationAboutTransportBack" onClick={() => checkAndMove(-1)}></div>
              <div id="titleContainer">{ selectedTransport.name }</div>
              <div id="informationAboutTransportForward" onClick={() => checkAndMove(1)}></div>
            </div>
            
            <div id="DetailsAbouteTransportRest">
              

              <div id="transitContainer">
                <div id="transitContainerFromPlace">
                  <div id="transitContainerFromPlaceName"> { (routes.length == 0) ? null : routes[0].fromPlace } </div>
                  <div id="transitContainerFromPlaceDate"> { (routes.length == 0) ? null :routes[0].date } </div>
                </div>
                <div id="transitContainerArrow"></div>
                <div id="transitContainerToPlace">
                  <div id="transitContainerToPlaceName"> { (routes.length == 0) ? null : routes[routes.length - 1].toPlace } </div>
                  <div id="transitContainerToPlaceDate"> { (routes.length == 0) ? null : routes[routes.length - 1].date } </div>
                </div>
              </div>

              <div id="companyContainer">
                <div id="companyContainerLogo"></div>
                <div id="companyContainerText">{ selectedTransport.clientName }</div>
              </div>

              <div id="aboutCarContainer">
                <div id="aboutCarContainerLogo"></div>
                <div id="aboutCarContainerText">
                  <div id="carNameContainer">{ selectedTransport.companyName }</div>
                  <div id="plateNumberContainer">{ generatePlateNumber() }</div>
                </div>
              </div>

              <div id="aboutDriverContainer">
                <div id="aboutDriverContainerLogo"></div>
                <div id="aboutDriverContainerText">
                  <div id="driverNameContainer">{ selectedTransport.driverName }</div>
                  <div id="driverPhoneContainer">{ generatePhoneNumber() }</div>
                </div>
              </div>

              <div id="aboutMetatagsContainer">
                <div id="numberofMetatagsContainer">Number of metatags:</div>
                <div id="acutalNumberMetatagsContainer">{ selectedTransport.tags.length }</div>
              </div>

              <div id="aboutServicesContainer">
                <div id="aboutServicesTitleContainer">Services Included:</div>
                <div id="listWithMetatgsContainer">{ getServices().map((service, index) => {
                  return <div className='listWithMetatgsContainerRow' key={`listWithMetatgsContainer_${index}`} id={`listWithMetatgsContainer_${index}`}>
                      { getListWithMetatgsContainerLogo(service, index) }
                      <div>{ service }</div>
                    </div>
                }) }</div>
              </div>
            </div>
          </div>

          <div id="DetailsWithMap">
            <APIProvider apiKey='AIzaSyAESs-zfu7Sdyv7cLQAvv93uNzJNWYbg_I'>
              <Map 
                zoom={6}
                center={center}
                options={{ 
                    styles: mapStyle,
                    fullscreenControl: false,
                    disableDefaultUI: true,
                    zoomControl: true,
                    zoomControlOptions: { position: 19 },
                }}
              >
                { 
                  markers == null ? null : markers.map((transport, index) => { 
                      return <Marker icon={getCustomMarkerSymbol()} options={{ color: 'blue' }} id="test" position={{lat: transport.lat, lng: transport.lng}} key={`marker_${index}`} text="1"></Marker> 
                  }) 
                }
              </Map>
            </APIProvider>
          </div>
        </div>

        <div id="RoadDetailsContainer">

          <div id="roadDetailsTitleContainer">
            <div id="doneKmsContainer">{ selectedTransport.totalDistance } km</div>
            <div id="remainingKmsContainer">out of { selectedTransport.totalDistance }</div>
          </div>

          {/* <div id="variableTimelineContainer"></div> timeline */}

          <div id="listWithCitiesTransportContainer">
            {
              getRoutes().map((route, index) => {
                return <ListWithCitiesTransportContainer key={`listWithCitiesTransportContainer_${index}`} route={route} />
              })
            }
          </div> {/* list with traseu with map and cell that collapse */}

        </div>
      </div>

      <OverviewFooter />
    </div>
  );

}
  
export default TrackingContainer;
  