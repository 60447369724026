import OverviewHeader from "../../Header/OverviewHeader";
import styles from "./OverviewContainerNoMarkers.module.css";
import image from "../../../../../images/GuyWithPackage.png";

export function OverviewContainerNoMarkers(props) {
  function handleClickGetStarted() {
    props.nextStep();
  }

  return (
    <div className={styles.rootContainer}>
      <div className={styles.noMarkersMainContainer}>
        <div className={styles.imageView}>
          <img src={image} />
        </div>
        <div className={styles.texts}>
          <div className={styles.title}>No Tracked Cargo Yet!</div>
          <div className={styles.subtitle}>
            Kickstart Your Tracking Journey with
            <br /> Your First Transport
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.textsButton} onClick={handleClickGetStarted}>
              GET STARTED
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
