import { useState } from "react";
import styles from "./OverviewContainerAddMarkersFlow.module.css";
import { OverviewContainerNoMarkers } from "./OverviewContainerNoMarkers";
import OverviewFooter from "../../Footer/OverviewFooter";
import { OverviewContaineSelectOptions } from "./OverviewContaineSelectOptions";
import OverviewHeader from "../../Header/OverviewHeader";
import { OverviewContainerSetup } from "./OverviewContainerSetup/OverviewContainerSetup";
import { AlertWithButtons } from "../../../../../Classes/AlertWithButtons/AlertWithButtons";

export function OverviewContainerAddMarkersFlow(props) {
  const [step, setStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const titles = ["Overview", "Transport Creation", "Transport Creation"];

  function nextStep() {
    setStep(step + 1);
  }

  function finish() {
    // setShowAlert(true)
    alert("Transport added!");
    setStep(0);
  }

  return (
    <div className={styles.rootContainer}>
      <div className={styles.headerContainer}>
        <OverviewHeader title={titles[step]} />
      </div>
      {step == 0 && <OverviewContainerNoMarkers nextStep={nextStep} />}
      {step == 1 && <OverviewContaineSelectOptions nextStep={nextStep} />}
      {step == 2 && <OverviewContainerSetup finish={finish} />}
      <OverviewFooter />
      {showAlert && <AlertWithButtons />}
    </div>
  );
}
