import styles from "./GreyInput.module.css";

export function GreyInput(props) {
  var composeStyles = [styles.rootContainer];
  if (props.addClasses) {
    composeStyles.push(props.addClasses);
  }

  return (
    <div className={composeStyles}>
      <div className={styles.placeholder}>{props.placeholder}</div>
      <input className={styles.input} />
    </div>
  );
}
