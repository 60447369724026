import { BlueButton } from "../BlueButton/BlueButton";
import styles from "./AlertWithButtons.module.css";

export function AlertWithButtons(props) {
  return (
    <div className={styles.rootContainer}>
      <div className={styles.alertContent}>
        <div className={styles.alert}>
          <div>Are you sure you want to confirm this transport</div>
          <div>
            All changes will be final. Make sure you double check all the
            information before confirming your transport.
          </div>
          <div>
            <BlueButton title="No, cancel transport" />
            <BlueButton title="Yes, confirm transport" />
          </div>
        </div>
      </div>
    </div>
  );
}
