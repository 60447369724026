import './AlertTransportCell.css';

function AlertTransportCell(props) {

    const getTextForAlert = () => {
        if (props.alert.type === "start") {
            return `Cargo started moving`
        } else if (["temperature", "uv", "light", "humidity", "gas"].includes(props.alert.type.toLowerCase())) {
            return `${props.alert.type} over the limit ${props.alert.value}`
        } else if (["motion", "pressure", "altitude"].includes(props.alert.type.toLowerCase())) {
            return `${props.alert.type} in the Road ${props.alert.value}`
        }  else if (["lost_signal", "proximity"].includes(props.alert.type.toLowerCase())) {
            return `${props.alert.type} value of ${props.alert.value}`
        } else if (props.alert.type === "stop") {
            return `Cargo at a stop for ${props.alert.value}`
        }
        return ""
    }

    const getIconForAlert = () => {
        if (props.alert.type === "start") {
            return <div className='iconForAlert iconStart'></div>
        } else if (["temperature", "uv", "light", "humidity", "gas"].includes(props.alert.type.toLowerCase())) {
            return <div className='iconForAlert iconHeat'></div>
        } else if (["motion", "pressure", "altitude"].includes(props.alert.type.toLowerCase())) {
            return <div className='iconForAlert iconBumps'></div>
        } else if (["lost_signal", "proximity"].includes(props.alert.type.toLowerCase())) {
            return <div className='iconForAlert iconGps'></div>
        } else if (props.alert.type === "stop") {
            return <div className='iconForAlert iconStop'></div>
        }
        return ""
    }

  return (
    <div id='AlertTransportCellContainer'>
        <div id="AlertTransportCellContainerRow">
            <div id="AlertTransportCellContainerRowHour">
                {props.alert.hour}
            </div>

            { getIconForAlert() }

            <div id="AlertTransportCellContainerRowType">
                {getTextForAlert()}
            </div>

            {props.alert.notificationSend ? <div id="AlertTransportCellContainerRowNotification">Notification sent</div> : null}

        </div>
        <div id="AlertTransportCellContainerDivider"></div>
    </div>
  );

}
  
export default AlertTransportCell;
  