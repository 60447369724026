import { GreyInput } from "../../../../../../Classes/GreyInput/GreyInput";
import styles from "./OverviewContainerSetup.module.css";
import mapDemo from "../../../../../../images/map-demo.png";
import plus from "../../../../../../images/plus.png";
import { PalletOption } from "../../../../../../Classes/PalletOption/PalletOption";
import { BlueButton } from "../../../../../../Classes/BlueButton/BlueButton";

export function OverviewContainerSetup(props) {
  const pallets = [1, 2, 3, 4];

  return (
    <div className={styles.rootContainer}>
      <div className={styles.titleTransportInformation}>
        Transport Information
      </div>
      <div className={styles.firstRow}>
        <div className={styles.transportName}>
          <GreyInput placeholder="Transport Name" />
        </div>
        <div className={styles.choseTheClient}>
          <GreyInput placeholder="Chose the Client" />
        </div>
      </div>

      <div className={styles.titleVehicleInformation}>Vehicle Information</div>
      <div className={styles.secondRow}>
        <div className={styles.vehicleModel}>
          <GreyInput placeholder="Vehicle Model" />
        </div>
        <div className={styles.year}>
          <GreyInput placeholder="Year" />
        </div>
        <div className={styles.vehicleNumber}>
          <GreyInput placeholder="Vehicle Number" />
        </div>
        <div className={styles.driverName}>
          <GreyInput placeholder="Driver Name" />
        </div>
        <div className={styles.driverNumber}>
          <GreyInput placeholder="Driver Number" />
        </div>
      </div>

      <div className={styles.titlePlanYourRoute}>Plan your route</div>
      <div className={styles.planYourRoute}>
        <div className={styles.stops}>
          <div className={styles.headerStops}>
            <div className={styles.headerDate}>Date</div>
            <div className={styles.headerLocation}>Location</div>
          </div>
          <div className={styles.firstStop}>
            <GreyInput addClasses={styles.firstStopInput} />
            <GreyInput />
          </div>
          <div className={styles.plus}>
            <img src={plus} />
          </div>
          <div className={styles.lastStop}>
            <GreyInput />
            <GreyInput />
          </div>
        </div>
        <div className={styles.map}>
          <img src={mapDemo} />
        </div>
      </div>

      <div className={styles.palletsOnBoard}>Pallets on board</div>
      <div className={styles.palletsOnBoardSubtext}>
        How many Pathium pallets will be included in your cargo?
      </div>
      <div className={styles.pallets}>
        {pallets.map((pallet, index) => {
          return <PalletOption key={index} pallet={pallet} />;
        })}
      </div>

      <div className={styles.finishContainer}>
        <BlueButton title="Finish Transport Creation" onClick={props.finish} />
      </div>
    </div>
  );
}
