import { useState } from "react";
import "./TransportsTableView.css";
import StaticFunctions from "../../../../../Managers/StaticFunctions";

function TransportsTableView(props) {
  const [extended, setExtended] = useState(false);

  const extendAction = () => {
    setExtended(!extended);
    if (!extended) {
      document
        .getElementsByClassName(props.theKey)[0]
        .classList.add("backgroundColor");
    } else {
      document
        .getElementsByClassName(props.theKey)[0]
        .classList.remove("backgroundColor");
    }
  };

  return (
    <div
      id="TransportsTableViewContainer"
      className={`${props.theKey}`}
      onClick={extendAction}
    >
      <div id="TransportsTableViewContainerHeader">
        <div className="tableCell">{props.transport.clientName}</div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.companyName}
        </div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.driverName}
        </div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.totalDistance} km
        </div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.name}
        </div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.routes == null
            ? null
            : props.transport.routes[0].locationName}
        </div>
        <div className="tableCell tableCellWithBorder">
          {props.transport.routes == null
            ? null
            : props.transport.routes[props.transport.routes.length - 1]
                .locationName}
        </div>
        <div className="tableCell tableCellWithBorder">
          {StaticFunctions.getAllServiesFromTags(props.transport)}
        </div>
        <div className="tableCell tableCellWithBorder">
          {StaticFunctions.getNumberOfAlerts(props.transport)}
        </div>
        {extended ? (
          <div id="TransportsTableViewContainerArrowUp"></div>
        ) : (
          <div id="TransportsTableViewContainerArrowDown"></div>
        )}
      </div>
      {extended ? (
        <div id="TransportsTableViewContainerContent">
          <div id="TransportsTableViewContainerContentSummary"></div>
          <div id="TransportsTableViewContainerContentLostSignal"></div>
          <div id="TransportsTableViewContainerContentBumpsInTheRoad">
            <div id="TransportsTableViewContainerContentBumpsInTheRoadText">
              Bumps in the road
            </div>
            <div id="TransportsTableViewContainerContentBumpsInTheRoadGraph"></div>
          </div>
          <div id="TransportsTableViewContainerContentColumn">
            <div id="TransportsTableViewContainerContentCargoIntact">
              <div id="TransportsTableViewContainerContentCargoIntactCircular"></div>
              <div id="TransportsTableViewContainerContentCargoIntactText">
                97% of the cargo intact
              </div>
            </div>
            <div id="TransportsTableViewContainerContentTemperature">
              <div id="TransportsTableViewContainerContentTemperatureText">
                Temperature
              </div>
              <div id="TransportsTableViewContainerContentTemperatureGraph"></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TransportsTableView;
