import "./OverviewContent.css";
import mapStyle from "./MapStyles.js";
import { useEffect, useState } from "react";
import OverviewContentDivClientsTableCell from "./OverviewContentDivClientsTableCell.js";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

function OverviewContent(props) {
  //   const getCustomMarkerSymbol = () => ({
  //     path: window.google.maps.SymbolPath.CIRCLE, // Access SymbolPath through window.google
  //     scale: 5, // Adjust the size of the circle as needed
  //     strokeColor: "#246BFD", // Change the stroke color
  //     fillColor: "#246BFD", // Change the fill color
  //     fillOpacity: 1, // Adjust the opacity
  //   });

  return (
    <div id="overviewContentDiv">
      <h4 id="overviewContentDivTransit">In transit cargo</h4>
      <div id="overviewContentDivMap">
        <div id="map2">
          <APIProvider apiKey="AIzaSyAESs-zfu7Sdyv7cLQAvv93uNzJNWYbg_I">
            <Map
              zoom={props.zoomLevel}
              center={props.center}
              options={{
                styles: mapStyle,
                fullscreenControl: false,
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: { position: 19 },
              }}
            >
              {props.markers == null
                ? null
                : props.markers.map((transport, index) => {
                    return (
                      <Marker
                        // icon={getCustomMarkerSymbol()}
                        options={{ color: "blue" }}
                        id="test"
                        position={{ lat: transport.lat, lng: transport.lng }}
                        key={`marker_${index}`}
                        text="1"
                      ></Marker>
                    );
                  })}
            </Map>
          </APIProvider>
        </div>
        <div id="overviewContentDivClients">
          <div className="OverviewContentDivClientsHeader">
            <div className="OverviewContentDivClientsHeaderLogo"></div>
            <div className="OverviewContentDivClientsHeaderCompanyName">
              Client
            </div>
            <div className="OverviewContentDivClientsHeaderLocation">Route</div>
            <div className="OverviewContentDivClientsHeaderDistance">
              Distance
            </div>
            <div className="OverviewContentDivClientsHeaderStatus">Status</div>
          </div>
          {props.transports == null
            ? null
            : props.transports.map((transport, index) => {
                return (
                  <OverviewContentDivClientsTableCell
                    key={`overviewContentDivClients_${index}`}
                    transport={transport}
                    goToTracking={props.goToTracking}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default OverviewContent;
