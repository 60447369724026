export default class StaticFunctions {
  static getAllServiesFromTags = (transport) => {
    if (transport.tags != null) {
      var services = [];
      var servicesString = "";

      transport.tags.forEach((tag) => {
        tag.services.forEach((service) => {
          if (!services.includes(service.substring(0, 4))) {
            services.push(service.substring(0, 4));
          }
        });
      });

      services.forEach((service, index) => {
        servicesString += service;
        if (index < services.length - 1) {
          servicesString += " - ";
        }
      });

      const numberOfLetters = 20;
      if (servicesString.length >= numberOfLetters) {
        return `${servicesString.substring(0, numberOfLetters)}...`;
      }

      return servicesString;
    }
    return "";
  };

  static getNumberOfAlerts = (transport) => {
    var numberOfEvents = 0;
    if (transport.tags != null) {
      transport.tags.forEach((tag) => {
        numberOfEvents += tag.events.length;
      });
    }
    return `${numberOfEvents} Alerts`;
  };
}
