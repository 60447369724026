import styles from "./PalletOption.module.css";
import sensors from "../../images/sensors.png";
import pallet from "../../images/pallet.png";

export function PalletOption(props) {
  return (
    <div key={props.key} className={styles.rootContainer}>
      <img className={styles.pallet} src={pallet} />
      <div className={styles.text}>Pally_TRK_000123</div>
      <img className={styles.sensors} src={sensors} />
    </div>
  );
}
