import OverviewContentLatestDataHeader from "../OverviewContentLatestDataHeader/OverviewContentLatestDataHeader";
import "./OverviewContentLatestDataCargoInDanger.css";

function OverviewContentLatestDataCargoInDanger(props) {
  const getNumberOfAlerts = (transport) => {
    var numberOfEvents = 0;
    if (transport.tags != null) {
      transport.tags.forEach((tag) => {
        numberOfEvents += tag.events.length;
      });
    }
    return numberOfEvents;
  };

  const checkAllNumberOfAlerts = () => {
    var numberOfEvents = 0;
    if (props.transports != null) {
      if (props.transports.length > 0) {
        props.transports.forEach((transport) => {
          transport.tags.forEach((tag) => {
            numberOfEvents += tag.events.length;
          });
        });
      }
    }
    return numberOfEvents;
  };

  return (
    <div id="overviewContentLatestDataCargoInDanger">
      <OverviewContentLatestDataHeader
        title="Cargo in danger"
        hideDownload={true}
      />
      {checkAllNumberOfAlerts() == 0 ? (
        <div id="OverviewContentLatestDataCargoInDangerContent">
          <div id="OverviewContentLatestDataCargoInDangerContentImage"></div>
          <div id="OverviewContentLatestDataCargoInDangerContentNoData">
            <div>No relevant data to show.</div>
            <div>All cargo is secure.</div>
          </div>
        </div>
      ) : (
        <div id="OverviewContentLatestDataCargoInDangerList">
          <div id="OverviewContentLatestDataCargoInDangerListHeader">
            <div id="OverviewContentLatestDataCargoInDangerListHeaderTransportName">
              TRANSPORT NAME
            </div>
            <div id="OverviewContentLatestDataCargoInDangerListHeaderNrOfAlerts">
              NR. OF ALERTS
            </div>
          </div>
          {props.transports.map((transport, index) => {
            if (getNumberOfAlerts(transport) != 0) {
              return (
                <div
                  className="OverviewContentLatestDataCargoInDangerListCell"
                  key={`OverviewContentLatestDataCargoInDangerList_${index}`}
                  onClick={() => props.goToTracking(transport.id)}
                >
                  <div id="">{transport.name}</div>
                  <div id="">{getNumberOfAlerts(transport)}</div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default OverviewContentLatestDataCargoInDanger;
